import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const PrimaryNav = ({ items }) => {
  return (
    <div className={clsx("flex flex-1 space-x-6")}>
      {items.map((item) => {
        return <_Item key={item.id} item={item} />;
      })}
    </div>
  );
};

const _Item = ({ item }) => {
  const router = useRouter();

  const isActive =
    router.pathname !== "/" &&
    `/${item.element?.uri}`.startsWith(router.pathname);

  return (
    <Link
      href={`/${item.element?.uri}`.replace(/([^:]\/)\/+/g, "$1")}
      className={clsx(
        "PrimaryNav__itemLink",
        "small-caps leading-0 after:hover-ball after:content[''] hover:after:hover-ball--active  flex items-center justify-center whitespace-nowrap rounded-full transition",
        {
          "after:hover-ball--active": isActive,
        }
      )}
    >
      <div>{item.title}</div>
    </Link>
  );
};

export default PrimaryNav;
