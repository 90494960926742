import SearchForm from "@/components/SearchForm";
import state from "@/state";
import { Popover, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Search } from "react-feather";
import { useSnapshot } from "valtio";

const SearchPopover = () => {
  const stateSnapshot = useSnapshot(state);

  return (
    <Popover className="relative">
      {({ open, close }) => {
        // Set the global state to the open state of the menu
        state.search.isOpen = open;

        return (
          <>
            <Popover.Button
              className={clsx(
                "flex h-12 w-12 items-center justify-center rounded-full text-black-dark dark:text-white transition duration-300 ease-out hover:bg-red hover:bg-red/80 active:mt-0.5 lg:bg-red lg:text-white hover:text-white",
                {
                  "bg-stone dark:bg-black-dark": !stateSnapshot.header.isPinned,
                },
              )}
              aria-label="Search"
            >
              <Search className={clsx("w-6 h-6 stroke-1")} />
            </Popover.Button>

            <Transition
              show={open}
              enter="transition duration-300 ease-in-out"
              enterFrom="transform opacity-0 -translate-y-full"
              enterTo="transform opacity-100 translate-y-0"
              leave="transition duration-300 ease-in-out"
              leaveFrom="transform opacity-100 translate-y-0"
              leaveTo="transform opacity-0 -translate-y-full"
              className="fixed inset-x-0 top-0 z-50 h-28 bg-red lg:h-44"
            >
              <Popover.Panel
                className={clsx("flex h-full items-center justify-center")}
              >
                <Transition.Child
                  enter="transition duration-500 ease-in-out delay-100"
                  enterFrom="transform opacity-0 -translate-y-2"
                  enterTo="transform opacity-100 translate-y-0"
                  leave="transition duration-300 ease-in-out"
                  leaveFrom="transform opacity-100 translate-y-0"
                  leaveTo="transform opacity-0 -translate-y-2"
                  className={clsx("relative w-full max-w-4xl px-6")}
                >
                  <SearchForm open={open} close={close} />
                </Transition.Child>
              </Popover.Panel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
};

export default SearchPopover;
