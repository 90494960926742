import clsx from "clsx";

const Instagram = ({ size = "sm" }: { size?: "sm" | "lg" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={clsx({
        "size-4": size === "sm",
        "size-6": size === "lg",
      })}
    >
      <path d="M16.98 0a6.9 6.9 0 0 1 5.08 1.98A6.94 6.94 0 0 1 24 7.02v9.96c0 2.08-.68 3.87-1.98 5.13A7.14 7.14 0 0 1 16.94 24H7.06a7.06 7.06 0 0 1-5.03-1.89A6.96 6.96 0 0 1 0 16.94V7.02C0 2.8 2.8 0 7.02 0h9.96zm.05 2.23H7.06c-1.45 0-2.7.43-3.53 1.25a4.82 4.82 0 0 0-1.3 3.54v9.92c0 1.5.43 2.7 1.3 3.58a5 5 0 0 0 3.53 1.25h9.88a5 5 0 0 0 3.53-1.25 4.73 4.73 0 0 0 1.4-3.54V7.02a5 5 0 0 0-1.3-3.49 4.82 4.82 0 0 0-3.54-1.3zM12 5.76c3.39 0 6.2 2.8 6.2 6.2a6.2 6.2 0 0 1-12.4 0 6.2 6.2 0 0 1 6.2-6.2zm0 2.22a3.99 3.99 0 0 0-3.97 3.97A3.99 3.99 0 0 0 12 15.92a3.99 3.99 0 0 0 3.97-3.97A3.99 3.99 0 0 0 12 7.98zm6.44-3.77a1.4 1.4 0 1 1 0 2.8 1.4 1.4 0 0 1 0-2.8z" />
    </svg>
  );
};

const YouTube = ({ size = "sm" }) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={clsx({
        "size-4": size === "sm",
        "size-6": size === "lg",
      })}
    >
      <path
        d="M12.04 3.5c.59 0 7.54.02 9.34.5a3.02 3.02 0 0 1 2.12 2.15C24 8.05 24 12 24 12v.04c0 .43-.03 4.03-.5 5.8A3.02 3.02 0 0 1 21.38 20c-1.76.48-8.45.5-9.3.51h-.17c-.85 0-7.54-.03-9.29-.5A3.02 3.02 0 0 1 .5 17.84c-.42-1.61-.49-4.7-.5-5.6v-.5c.01-.9.08-3.99.5-5.6A3.02 3.02 0 0 1 2.62 4c1.8-.49 8.75-.51 9.34-.51l.08.01Zm-2.5 4.9v7.18L15.82 12 9.54 8.41V8.4Z"
        fill="currentColor"
      />
    </svg>
  );
};

const LinkedIn = ({ size = "sm" }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx({
        "size-4": size === "sm",
        "size-6": size === "lg",
      })}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.287 5.98h2.476v1.233c.356-.71 1.271-1.347 2.645-1.347 2.634 0 3.26 1.412 3.26 4.003v4.798H12v-4.208c0-1.476-.357-2.308-1.265-2.308-1.26 0-1.783.897-1.783 2.307v4.209H6.287V5.979Zm-4.573 8.573h2.667V5.866H1.714v8.687Zm3.049-11.52a1.687 1.687 0 0 1-.502 1.202 1.727 1.727 0 0 1-2.425 0 1.699 1.699 0 0 1-.502-1.202c0-.451.18-.883.502-1.202a1.722 1.722 0 0 1 2.425 0c.321.319.502.751.502 1.202Z"
        fill="currentColor"
      />
    </svg>
  );
};

const Twitter = ({ size = "sm" }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx({
        "size-4": size === "sm",
        "size-6": size === "lg",
      })}
    >
      <path
        d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.684 6.684 0 0 0 16 3.542c-.6.265-1.237.44-1.889.518a3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.315 6.315 0 0 1-.78-.045A9.343 9.343 0 0 0 5.026 15Z"
        fill="currentColor"
      />
    </svg>
  );
};

const X = ({ size = "sm" }) => {
  return (
    <svg
      viewBox="0 0 1200 1227"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx({
        "size-4": size === "sm",
        "size-6": size === "lg",
      })}
    >
      <g clipPath="url(#clip0_0_3)">
        <path
          d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_3">
          <rect width="1200" height="1227" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Email = ({ size = "sm" }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx({
        "size-4": size === "sm",
        "size-6": size === "lg",
      })}
    >
      <path
        d="M3.73294 2.4C3.16715 2.4 2.62453 2.636 2.22445 3.05608C1.82437 3.47616 1.59961 4.04591 1.59961 4.64V4.86512L7.99961 8.48384L14.3996 4.86624V4.64C14.3996 4.04591 14.1748 3.47616 13.7748 3.05608C13.3747 2.636 12.8321 2.4 12.2663 2.4H3.73294ZM14.3996 6.13744L8.25241 9.6128C8.17471 9.65672 8.08785 9.67971 7.99961 9.67971C7.91137 9.67971 7.82451 9.65672 7.74681 9.6128L1.59961 6.13744V11.36C1.59961 11.9541 1.82437 12.5238 2.22445 12.9439C2.62453 13.364 3.16715 13.6 3.73294 13.6H12.2663C12.8321 13.6 13.3747 13.364 13.7748 12.9439C14.1748 12.5238 14.3996 11.9541 14.3996 11.36V6.13744Z"
        fill="currentColor"
      />
    </svg>
  );
};

const SocialIcons = () => {};

SocialIcons.Instagram = Instagram;
SocialIcons.YouTube = YouTube;
SocialIcons.LinkedIn = LinkedIn;
SocialIcons.Twitter = Twitter;
SocialIcons.Email = Email;
SocialIcons.X = X;

export default SocialIcons;
