import DarkModeToggle from "@/components/DarkModeToggle";
import Lockup from "@/components/Lockup";
import PrimaryNav from "@/components/PrimaryNav";
import SearchPopover from "@/components/SearchPopover";
import useScrollSpy from "@/hooks/useScrollSpy";
import state from "@/state";
import { Transition } from "@headlessui/react";
import clsx from "clsx";
import Link from "next/link";
import { useSnapshot } from "valtio";

const Header = ({ primaryNavigation = [] }) => {
  const stateSnapshot = useSnapshot(state);

  return (
    <>
      <Transition
        show={stateSnapshot.header.isTop || stateSnapshot.header.isPinned}
        enter="transition "
        enterFrom="-translate-y-full"
        enterTo="translate-y-0"
        leave="transition "
        leaveFrom="translate-y-0"
        leaveTo="-translate-y-full"
        as="header"
        className={clsx(
          "Header",
          "fixed left-0 right-0 top-0 z-30 ease-out duration-300",
          {
            "border-b border-b-stone-dark bg-stone dark:border-b-black-light dark:bg-black":
              stateSnapshot.header.isPinned &&
              !stateSnapshot.header.isTop &&
              stateSnapshot.isScrolledPastHero,
          },
        )}
      >
        <div className={clsx("Header__container", "container relative z-20")}>
          <div className={clsx("", "flex h-28 items-center lg:h-44")}>
            <div className={clsx("Header__lockup", "h-full py-6 lg:py-14")}>
              <Link
                href="/"
                className={clsx("block h-full ")}
                aria-label="Go to homepage"
              >
                <Lockup />
              </Link>
            </div>
            <div
              className={clsx(
                "Header__nav",
                "ml-auto flex items-center justify-center space-x-6",
              )}
            >
              <div
                className={clsx(
                  "Header__primaryNav",
                  "relative hidden grow lg:block",
                )}
              >
                <PrimaryNav items={primaryNavigation} />
              </div>
              <div
                className={clsx(
                  "Header__darkModeToggle",
                  "hidden items-center lg:flex",
                )}
              >
                <DarkModeToggle />
              </div>
              <div
                className={clsx(
                  "Header__searchToggle",
                  "hidden items-center lg:flex",
                )}
              >
                <SearchPopover />
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <ScrollSpy />
    </>
  );
};

const ScrollSpy = () => {
  useScrollSpy();

  return null;
};

export default Header;
