import Newsletter from "@/components/Newsletter";
import SocialLinks from "@/components/SocialLinks";
import clsx from "clsx";
import Link from "next/link";
import { useInView } from "react-intersection-observer";

const Footer = ({ navigation, copyrightNavigation }) => {
  const { ref, inView, entry } = useInView({ triggerOnce: true });

  if (!navigation) return null;

  return (
    <>
      <footer
        className={clsx("Footer", "bg-black dark:bg-black-dark")}
        ref={ref}
      >
        <div className={clsx("Footer__content", "container")}>
          <div className={clsx("grid grid-cols-24 lg:gap-8")}>
            <div className={clsx("col-span-24 py-24 lg:col-span-16 lg:py-40")}>
              <div
                className={clsx(
                  "heading-lg lg:heading-2xl prose text-stone prose-a:text-red prose-a:decoration-2 prose-a:underline-offset-8",
                )}
              >
                <p>
                  Talk to us about how language can strengthen your brand and
                  business.{" "}
                  <a href="mailto:hello@reedwords.com">hello@reedwords.com</a>
                </p>
              </div>
            </div>
            <div
              className={clsx(
                "lg:col-start-20 col-span-24 mb-12 lg:col-span-6 lg:py-40",
              )}
            >
              <div className={clsx("text-stone")}>
                <div className={clsx("mb-12")}>
                  <Newsletter />
                </div>
                <div>
                  <SocialLinks />
                </div>
              </div>
            </div>
            <div className={clsx("col-span-24 pb-12")}>
              <div className={clsx("small-caps text-sm text-stone")}>
                {new Date().getFullYear()} Reed words. All rights reserved.
              </div>
              <ul className={clsx("flex space-x-4 text-stone")}>
                {copyrightNavigation.map((item) => {
                  return (
                    <li key={item.id}>
                      <Link
                        key={item.id}
                        href={`/${item.element?.uri}`}
                        className={clsx("small-caps text-sm")}
                      >
                        {item.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
