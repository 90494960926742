import state from "@/state";
import clsx from "clsx";
import Link from "next/link";
import { useSnapshot } from "valtio";

const MobilePrimaryNav = ({ items }) => {
  const stateSnapshot = useSnapshot(state);

  return (
    <ul className={clsx("MobilePrimaryNav", "space-y-5")}>
      {items.map((item) => {
        return (
          <li key={item.id} className={clsx("MobilePrimaryNav__item")}>
            <Link
              href={`/${item.element?.uri}`}
              className={clsx(
                "MobilePrimaryNav__link",
                "heading-xl hover:text-red transition duration-300 ease-out",
              )}
            >
              <span className={clsx("MobilePrimaryNav__label")}>
                {item.title}
              </span>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default MobilePrimaryNav;
