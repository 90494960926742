import { proxy } from "valtio";

const state = proxy({
  isScrolledPastHero: false,
  search: { isOpen: false },
  header: {
    isPinned: true,
    isTop: true,
  },
  primaryNav: {
    activeItemId: null,
  },
  mobileNav: {
    isActive: false,
  },
  siteMenu: {
    isActive: false,
  },
});

export default state;
