import clsx from "clsx";
import { ArrowRight } from "react-feather";

const Newsletter = () => {
  return (
    <div className={clsx("Newsletter", "w-full")}>
      <div className={clsx("mb-6 text-xl font-medium")}>
        Get Fresh Words – Our monthly newsletter on all the things words can do.
      </div>
      <form
        className={clsx("relative")}
        action="https://reedwords.us12.list-manage.com/subscribe/post?u=8b5d6d4aba056e5107fccf44a&amp;id=f1f01745f1&amp;f_id=005eb9e0f0"
        method="post"
        target="_blank"
      >
        <input
          type="email"
          className={clsx(
            "small-caps h-10 w-full border-b bg-transparent focus:outline-none",
          )}
          name="EMAIL"
          placeholder="Email"
        />
        <button
          className={clsx(
            "absolute top-0 right-0 flex h-10 w-10 items-center justify-end",
          )}
          aria-label="Subscribe to newsletter"
        >
          <ArrowRight size={16} strokeWidth={1.5} />
        </button>
      </form>
    </div>
  );
};

export default Newsletter;
