import state from "@/state";
import clsx from "clsx";
import { X } from "react-feather";
import { useSnapshot } from "valtio";

const MobileMenuToggle = () => {
  const stateSnapshot = useSnapshot(state);

  const handleClick = () => {
    state.mobileNav.isActive = !stateSnapshot.mobileNav.isActive;
  };

  const icon = stateSnapshot.mobileNav.isActive ? (
    <X className={clsx("w-7 h-7 stroke-[0.9]")} />
  ) : (
    <svg
      viewBox="0 0 36 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("w-8 h-8 stroke-1")}
    >
      <path d="M0 1H36" stroke="currentColor" />
      <path d="M0 6H36" stroke="currentColor" />
      <path d="M0 11H36" stroke="currentColor" />
    </svg>
  );
  return (
    <button
      className={clsx(
        "MobileMenuToggle",
        "flex h-12 w-12 items-center justify-center border border-transparent transition-colors rounded-full",
        {
          "text-gray-900 dark:text-white": stateSnapshot.mobileNav.isActive,
          "bg-stone dark:bg-black-dark": !stateSnapshot.header.isPinned,
        },
      )}
      onClick={handleClick}
    >
      {icon}
    </button>
  );
};

export default MobileMenuToggle;
