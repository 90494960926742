import Target from "@/components/Target";
import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { useEffect, useState } from "react";

const CTAButton = ({ target = {} }) => {
  const [isShowing, setIsShowing] = useState(false);
  const [currentTarget, setCurrentTarget] = useState({});

  useEffect(() => {
    if (!target || !target.url) {
      setIsShowing(false);
    }

    // Don't change the cta if it's the same
    if (target.url === currentTarget.url) return;

    setIsShowing(false);

    setTimeout(() => {
      setCurrentTarget(target);
      setIsShowing(true);
    }, 1000);
  }, [target, currentTarget?.url]);

  return (
    <Transition
      show={!!currentTarget.url && isShowing}
      className={clsx("fixed bottom-8 right-8 z-50 lg:bottom-16 lg:right-16")}
      enter="transition duration-500 ease-out-back"
      enterFrom="opacity-0 translate-y-8"
      enterTo="opacity-100 translate-y-0"
      leave="transition duration-500 ease-in-back"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-2"
    >
      {!!currentTarget.url && !!currentTarget.text && (
        <Target
          target={currentTarget}
          className={clsx(
            "shadow-max flex items-center space-x-3 rounded-full bg-white px-8 py-4 dark:bg-black",
          )}
        >
          <div
            className={clsx("small-caps whitespace-nowrap")}
            dangerouslySetInnerHTML={{ __html: currentTarget.text }}
          />
        </Target>
      )}
    </Transition>
  );
};

export default CTAButton;
