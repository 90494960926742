import clsx from "clsx";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { ArrowRight, Search } from "react-feather";
import { useSnapshot } from "valtio";
import state from "../state";

const SearchForm = ({
  initialQuery = "",
  open = true,
  close = () => null,
  theme = "light",
}) => {
  const router = useRouter();

  const [search, setSearch] = useState(initialQuery);

  const inputRef = useRef();

  const handleSubmit = (ev) => {
    ev.preventDefault();

    router.push({ pathname: "/search", query: { query: search } });
    close();
  };

  useEffect(() => {
    if (open) inputRef.current.focus();
  }, [open]);

  useEffect(() => {
    if (initialQuery) setSearch(initialQuery);
  }, [initialQuery]);

  // Use a different placeholder text depending on viewport width
  const placeholderText = () => {
    // Check if the window object is available
    if (typeof window !== "undefined") {
      if (window.innerWidth < 640) return "Search our site";
    }
    return "Search articles, case studies, and more";
  };

  return (
    <form className={clsx("w-full")} onSubmit={handleSubmit}>
      <input
        ref={inputRef}
        type="text"
        value={search}
        onChange={(ev) => setSearch(ev.target.value)}
        className={clsx(
          "heading-md h-16 w-full border-b  bg-transparent  transition duration-300 ease-out   focus:outline-none lg:h-20",
          {
            "border-b-white/50 text-white placeholder:text-white/90 focus:border-b-white/100":
              theme === "light",
            "border-b-black/50 text-black placeholder:text-black/90 focus:border-b-black/100 dark:border-b-white/50 dark:text-white dark:placeholder:text-white/90 dark:focus:border-b-white/100":
              theme === "dark",
          }
        )}
        placeholder={placeholderText()}
      />
      <button
        className={clsx(
          "absolute bottom-0 right-6 top-0 flex h-full w-16 items-center justify-end lg:right-0",
          {
            "text-white": theme === "light",
            "text-black dark:text-white": theme === "dark",
          }
        )}
      >
        <ArrowRight className={clsx("w-8")} strokeWidth={1} />
      </button>
    </form>
  );
};

export default SearchForm;
