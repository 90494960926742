import state from "@/state";
import { useEffect } from "react";
import { useScrollData } from "scroll-data-hook";

const useScrollSpy = () => {
  const { scrolling, direction, position } = useScrollData();

  useEffect(() => {
    if (!scrolling) return;

    if (position.y > 20 && direction.y === "down") {
      state.header.isPinned = false;
    }
    if (position.y < 20 || direction.y === "up") {
      state.header.isPinned = true;
    }

    state.header.isTop = position.y < 5;
    state.isScrolledPastHero = position.y > 60;

    state.siteMenu.isActive = false;
  }, [position.y, scrolling, direction.y]);

  return;
};

export default useScrollSpy;
