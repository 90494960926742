import clsx from "clsx";

const DarkModeToggle = () => {
  const handleClick = () => {
    localStorage.theme = localStorage.theme === "dark" ? "light" : "dark";

    if (localStorage.theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  };

  return (
    <button
      onClick={handleClick}
      className={clsx(
        "DarkModeToggle",
        "flex h-12 w-12 items-center justify-center rounded-full",
      )}
      aria-label="Toggle dark mode"
    >
      <svg
        width="24"
        height="16"
        viewBox="0 0 24 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="16"
          cy="8"
          r="8"
          fill="#292626"
          className={clsx("opacity-100 transition-opacity dark:opacity-0")}
        />
        <circle
          cx="8"
          cy="8"
          r="7.5"
          fill="#EEE9E6"
          stroke="#292626"
          className={clsx("opacity-100 transition-opacity dark:opacity-0")}
        />

        <circle
          cx="8"
          cy="8"
          r="8"
          fill="#EEE9E6"
          className={clsx("opacity-0 transition-opacity dark:opacity-100")}
        />
        <circle
          cx="16"
          cy="8"
          r="7.5"
          fill="#292626"
          stroke="#EEE9E6"
          className={clsx("opacity-0 transition-opacity dark:opacity-100")}
        />
      </svg>
    </button>
  );
};

export default DarkModeToggle;
