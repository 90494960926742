import CTAButton from "@/components/CTAButton";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import MobileMenu from "@/components/MobileMenu";
import MobileMenuToggle from "@/components/MobileMenuToggle";
import SearchPopover from "@/components/SearchPopover";
import clsx from "clsx";
import Link from "next/link";
import { useEffect, useRef } from "react";
import CookieConsent from "react-cookie-consent";
import smartquotes from "smartquotes";

export default function Layout({
  children,
  primaryNavigation,
  footerNavigation,
  copyrightNavigation,
  ctaButton,
}) {
  const primaryNavigationRef = useRef(primaryNavigation);
  const footerNavigationRef = useRef(footerNavigation);
  const copyrightNavigationRef = useRef(copyrightNavigation);

  useEffect(() => {
    smartquotes().listen();
  }, []);

  return (
    <>
      <div className={clsx("Layout", {})}>
        <div className={clsx("Layout__content", "relative z-10 min-h-screen")}>
          <Header primaryNavigation={primaryNavigationRef.current} />

          <div
            className={clsx(
              "fixed right-[80px] top-6 z-50 block lg:right-24 lg:hidden",
            )}
          >
            <SearchPopover />
          </div>

          <div className={clsx("fixed top-6 right-6 z-40 block lg:hidden")}>
            <MobileMenuToggle />
          </div>

          <MobileMenu items={primaryNavigationRef.current} />

          <main className={clsx("Layout__main", "")}>{children}</main>
        </div>

        <Footer
          navigation={footerNavigationRef.current}
          copyrightNavigation={copyrightNavigationRef.current}
        />
      </div>
      <CTAButton target={ctaButton} />
      <CookieConsent
        disableStyles
        containerClasses={clsx(
          "fixed !bottom-6 left-6 z-50 lg:!bottom-16 lg:left-16 opacity-100 translate-y-0 shadow-max flex items-center lg:space-x-6 rounded-full bg-white dark:bg-black px-8 py-4 flex right-6 lg:right-auto flex-col lg:flex-row lg:items-center text-center space-y-4 lg:space-y-0 rounded-lg lg:rounded-full",
        )}
        overlayClasses={clsx("bg-black opacity-50")}
        contentClasses={clsx("")}
        buttonClasses={clsx(
          "group relative rounded-[100%] text-center transition active:top-[1px] inline-flex justify-center items-center bg-red text-stone hover:opacity-90 py-4 px-6 lg:py-5 lg:px-8 small-caps lg:ml-auto",
        )}
      >
        By using this site you're agreeing to{" "}
        <Link
          href="/privacy-policy"
          className={clsx(
            "underline decoration-from-font underline-offset-4 hover:decoration-gray-300 hover:opacity-75",
          )}
        >
          our privacy policy
        </Link>
        .
      </CookieConsent>
    </>
  );
}
