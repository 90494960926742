import DarkModeToggle from "@/components/DarkModeToggle";
import MobilePrimaryNav from "@/components/MobilePrimaryNav";
import Search from "@/components/SearchPopover";
import SocialLinks from "@/components/SocialLinks";
import state from "@/state";
import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useSnapshot } from "valtio";

const MobileMenu = ({ items }) => {
  const router = useRouter();

  const stateSnapshot = useSnapshot(state);

  const handleClick = () => {
    state.mobileNav.isActive = false;
  };

  // Close modal on nav change
  useEffect(() => {
    const routeChangeEnd = () => {
      state.mobileNav.isActive = false;
    };

    router.events.on("routeChangeStart", routeChangeEnd);

    return () => {
      router.events.off("routeChangeStart", routeChangeEnd);
    };
  }, [router]);

  return (
    <Transition show={stateSnapshot.mobileNav.isActive || false}>
      <Transition.Child
        enter={clsx("transition ease-in-out duration-500")}
        enterFrom={clsx("opacity-0")}
        enterTo={clsx("opacity-100")}
        leave={clsx("transition ease-in-out duration-1000")}
        leaveFrom={clsx("opacity-100")}
        leaveTo={clsx("opacity-0")}
        className={clsx(
          "MobileMenu",
          "fixed inset-0 z-30 block bg-black/20 lg:hidden",
        )}
      >
        <div className={clsx("MobileMenu__wrapper", "absolute inset-0 pl-8")}>
          <Transition.Child
            className={clsx(
              "MobileMenu__content",
              "flex h-full flex-col bg-stone pl-8 pt-32 shadow-2xl dark:bg-black",
            )}
            enter={clsx("transition ease-out duration-500")}
            enterFrom={clsx("opacity-0 translate-x-4")}
            enterTo={clsx("opacity-100 translate-x-0")}
            leave={clsx("transition ease-out duration-500")}
            leaveFrom={clsx("opacity-100 translate-x-0")}
            leaveTo={clsx("opacity-0 translate-x-4")}
          >
            <div>
              <Transition.Child
                className={clsx("MobileMenu__navigation")}
                enter={clsx("transition ease-out delay-200 duration-700")}
                enterFrom={clsx("opacity-0 translate-x-4")}
                enterTo={clsx("opacity-100 translate-x-0")}
                leave={clsx("transition ease-out duration-500")}
                leaveFrom={clsx("opacity-100 translate-x-0")}
                leaveTo={clsx("opacity-0 translate-x-4")}
              >
                <MobilePrimaryNav items={items} />
              </Transition.Child>

              <Transition.Child
                className={clsx("MobileMenu__darkModeToggle", "pt-8")}
                enter={clsx("transition ease-out delay-300 duration-700")}
                enterFrom={clsx("opacity-0 translate-x-4")}
                enterTo={clsx("opacity-100 translate-x-0")}
                leave={clsx("transition ease-out duration-700")}
                leaveFrom={clsx("opacity-100 translate-x-0")}
                leaveTo={clsx("opacity-0 translate-x-4")}
              >
                <DarkModeToggle />
              </Transition.Child>
            </div>
            <Transition.Child
              className={clsx("MobileMenu__socialLinks", "mt-auto pt-8 pb-8")}
              enter={clsx("transition ease-out delay-500 duration-1000")}
              enterFrom={clsx("opacity-0 translate-x-4")}
              enterTo={clsx("opacity-100 translate-x-0")}
              leave={clsx("transition ease-out duration-700")}
              leaveFrom={clsx("opacity-100 translate-x-0")}
              leaveTo={clsx("opacity-0 translate-x-4")}
            >
              <SocialLinks />
            </Transition.Child>
          </Transition.Child>
        </div>
      </Transition.Child>
    </Transition>
  );
};

export default MobileMenu;
