import Layout from "@/layouts/Layout";
import "@/styles/globals.css";
import ProgressBar from "@badrap/bar-of-progress";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import Router from "next/router";
import type { ReactElement, ReactNode } from "react";
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";

declare global {
  interface Window {
    dataLayer: any;
  }
}

const progress = new ProgressBar({
  size: 2,
  color: "#000000",
  className: "bar-of-progress",
  delay: 100,
});

Router.events.on("routeChangeStart", () => progress.start());
Router.events.on("routeChangeComplete", () => progress.finish());
Router.events.on("routeChangeError", () => progress.finish());

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  // Get some props
  const {
    pageEntry,
    primaryNavigation,
    footerNavigation,
    copyrightNavigation,
  } = pageProps || {};

  /*
		Init tag manager
	*/

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") return;
    if (!process.env.NEXT_PUBLIC_GTM_ID) return;

    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID });
  }, []);

  // Use the layout defined at the page level, if available
  const getLayout =
    Component.getLayout ??
    ((page) => (
      <Layout
        primaryNavigation={primaryNavigation}
        footerNavigation={footerNavigation}
        copyrightNavigation={copyrightNavigation}
        ctaButton={pageEntry?.ctaButton}
      >
        {page}
      </Layout>
    ));

  return getLayout(<Component {...pageProps} />);
}
