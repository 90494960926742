import SocialIcons from "@/components/SocialIcons";
import clsx from "clsx";

const SocialLinks = () => {
  const className =
    "flex items-center justify-center h-6 w-6 transition-opacity hover:opacity-75";

  return (
    <div>
      <div className={clsx("flex items-center space-x-6")}>
        <a
          href="https://www.instagram.com/reedwords/"
          target="_blank"
          rel="noreferrer"
          className={clsx(className)}
          aria-label="Instagram"
        >
          <SocialIcons.Instagram />
        </a>
        <a
          href="https://www.youtube.com/c/ReedWordsLondon"
          target="_blank"
          rel="noreferrer"
          className={clsx(className)}
          aria-label="YouTube"
        >
          <SocialIcons.YouTube />
        </a>
        <a
          href="https://www.linkedin.com/company/reed-words"
          target="_blank"
          rel="noreferrer"
          className={clsx(className)}
          aria-label="LinkedIn"
        >
          <SocialIcons.LinkedIn />
        </a>
        <a
          href="https://x.com/reedwords"
          target="_blank"
          rel="noreferrer"
          className={clsx(className)}
          aria-label="X"
        >
          <SocialIcons.X />
        </a>
      </div>
    </div>
  );
};

export default SocialLinks;
